import React, { useContext, useState, useEffect } from 'react';

import {
  Box,
  Stack,
  Typography,
  Card,
  Checkbox,
  Chip,
  Button
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import PersonIcon from '@mui/icons-material/Person';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import getSymbolFromCurrency from 'currency-symbol-map';

import Trial from './trial';
import { BillingContext } from '@context/billing';
import Image from 'mui-image';
import { useTheme } from '@emotion/react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { LoadingButton } from '@mui/lab';
import Stepper from './stepper';
import { EventsContext } from '@context/events';
import { DeleteAccountDialog } from '@components/v2/organisms';

const subscriptionPeriodToNumberOfMonths = (offering) => {
  switch(offering.rcOffering?.product?.subscriptionPeriod){
  case 'P1Y':
    return 12;
  case 'P6M':
    return 6;
  case 'P1M':
  default:
    return 1;
  }
};

export default function Paywall(props) {
  const { handleDelete } = props;
  const { subscription, offerings, purchasePackage, isPurchasingPackage } = useContext(BillingContext);
  const { trackEvent } = useContext(EventsContext);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isDeleteAccountOpen, setIsDeleteAccountOpen] = useState(false);
  const [shouldShowDropOffScreen, setShouldShowDropOffScreen] = useState(false);
  const theme = useTheme();
  const history = useHistory();

  const handlePurchase = () => {
    trackEvent('Payments:Subscription:Plan:Selected');
    purchasePackage(selectedPackage.rcOffering, () => {
      trackEvent('Payments:Subscription:Plan:Purchase');
      history.push('/');
    });
  };

  const BulletPoint = (props) => {
    const { title, message, icon } = props;

    return (
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ 
          color: theme.palette.braceGreenDark.main
        }}>
          {icon}
        </Box>
        <Box sx={{ paddingLeft: 2 }}>
          <Box>
            <Typography variant='body1'>
              {title}
            </Typography>
          </Box>
          <Box>
            <Typography variant='body1'>
              {message}
            </Typography>
          </Box>
        </Box>
        
      </Box>
    );
  };

  const Offering = (props) => {
    const { offering } = props;

    const isSelected = selectedPackage?.id == offering.id;

    var title = '1 Month for ';
    var message = 'when billed monthly';

    if(offering.id?.toLowerCase().includes('annual')){
      title = '12 Months for ';
      message = 'when billed annually';
    }
    if(offering.id?.toLowerCase().includes('six_month')){
      title = '6 Months for ';
      message = 'when billed every 6 months';
    }

    var pricePerMonth = offering.rcOffering?.product?.price / subscriptionPeriodToNumberOfMonths(offering);
    title = `${title} ${getSymbolFromCurrency(offering.currencyCode) ?? offering.currencyCode}${(Math.round(pricePerMonth * 100, 2) / 100).toFixed(2)}/mo`;

    const calculateSaving = (offering) => {
      var highestPricePerMonth = 0;
      
      offerings.forEach(o => {
        var pricePerMonth = o.rcOffering?.product?.price / subscriptionPeriodToNumberOfMonths(o);
        if(pricePerMonth > highestPricePerMonth){
          highestPricePerMonth = pricePerMonth;
        }
      });
      
      var absoluteSaving = highestPricePerMonth - offering.rcOffering?.product?.price / 
      subscriptionPeriodToNumberOfMonths(offering);
      
      return Math.round((absoluteSaving * 100) / highestPricePerMonth);
    
    };

    var saving = calculateSaving(offering);

    return (
      <Card variant='outlined'
        sx={{ display: 'flex', width: '100%', p: 2, borderColor: isSelected ? theme.palette.braceGreenDark.main : theme.palette.divider }}
        onClick={() => setSelectedPackage(offering)}
      >
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display : 'flex', width: '100%', alignItems: 'center' }}>
            <Checkbox
              size='large'
              onClick={() => setSelectedPackage(offering)}
              sx={{ p: 0 }}
              checked={isSelected}
              icon={<RadioButtonUncheckedIcon fontSize='small' />}
              checkedIcon={<CheckCircleIcon fontSize='small' color='braceGreenDark' />} />

            <Typography variant='body1' sx={{ flexGrow: 1, paddingLeft: 1 }}>
              {title}
            </Typography>
            {saving > 0 &&
            <Chip 
              variant={isSelected ? 'filled' : 'outlined'}
              color='braceGreenDark'
              size='small'
              label= {`${saving}% OFF`}/>
            }
          </Box>
          <Box>
            <Typography variant='body1'>
              {message}
            </Typography>
          </Box>
          <Box>
            <Typography variant='body1'>
            </Typography>
          </Box>
        </Box>
      </Card>
    );
  };

  useEffect(() => {
    if(subscription?.id){
      trackEvent('Payments:Paywall:Open');
    }
  }, []);

  return (
    <Box sx={{ height: '100%' }}>
      {!subscription && <Trial openDeleteAccount={() => setIsDeleteAccountOpen(true)} />}
      {(subscription && !shouldShowDropOffScreen) && 
         <Box sx={{ py: 4, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
           <Box>
             <Image 
               duration={500} 
               width='200px' 
               src='https://bracesocial-prod-cdn.azureedge.net/static/Brace_Logo_Master_Dark and grad.png'/>
           </Box>
           <Box sx={{ p: 2, paddingTop: 4, flexGrow: 1, color: theme.palette.braceGreenDark.main }}>
             <Typography variant='h4' fontWeight={'medium'} textAlign='center' >
                Empower Your Recovery, Come Back Stronger
             </Typography>
             <Box sx={{ paddingTop: 2 }}>

               <BulletPoint title='Prioritise Your Health & wellbeing' icon={<AddIcon />}/>
               <BulletPoint title='Stay On Track With Your Recovery Plan' icon={<DoneIcon />}/>
               <BulletPoint title='Access Community Insights & Motivation' icon={<PersonIcon />}/>
             </Box>
             <Stack spacing={1} sx={{ paddingTop: 4 }}>
               {offerings.map(o => <Offering key={o.id} offering={o}/>)}
             </Stack>
           </Box>
           <Box sx={{ width: '100%', p: 2 }}>
             <LoadingButton 
               sx={{ py: 1, px: 4, fontSize: 'h6.fontSize' }}
               size='large'
               variant='contained'
               color='braceGreenDark'
               fullWidth={true}
               disabled={!selectedPackage}
               loading={isPurchasingPackage}
               onClick={handlePurchase}
             >
                Continue
             </LoadingButton>
           </Box>
           {(subscription?.isTrial && subscription.status != 'EXPIRED') &&
           <Button 
             sx={{ px: 4 }}
             color='braceGreenDark'
             fullWidth={true}
             onClick={() => history.goBack()}
           >
            Cancel
           </Button>
           }
           {(!subscription?.isTrial || subscription.status == 'EXPIRED') &&
              <Button 
                sx={{ textDecoration: 'underline', ':hover': { textDecoration: 'underline' } }}
                size='small'
                color='braceGreenDark'
                onClick={() => setShouldShowDropOffScreen(true)}
              >
                Not Right Now
              </Button>
           }

         </Box>
      }
      {(subscription && shouldShowDropOffScreen) &&
        <Box sx={{ py: 4, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', p: 2, paddingTop: 3 }}>
            <Image 
              duration={500} 
              width='200px' 
              src='https://bracesocial-prod-cdn.azureedge.net/static/Brace_Logo_Master_Dark and grad.png'/>
          </Box>
          <Box sx={{ px: 2, paddingBottom: 1, fontWeight: 'medium', textAlign: 'center', color: theme.palette.braceGreenLight.main }}>
            <Typography variant='h5'>Recovery Is A Tough Journey.</Typography>
          </Box>
          <Box sx={{ px: 1, fontWeight: 'medium', textAlign: 'center', color: theme.palette.braceGreenDark.main  }}>
            <Typography variant='h6'>Brace is empowering our community with the physical and mental health supports to come back stronger, together.</Typography>
          </Box>
          <Box sx={{ flexGrow: 1, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Stepper />
          </Box>
          <Box sx={{ px: 2, paddingBottom: 3, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Button 
              sx={{ marginX: 2, marginTop: 2, marginBottom: 1, py: 1, px: 4, fontSize: 'h6.fontSize', width: '66%', minWidth: '250px' }}
              size='large'
              variant='contained'
              color='braceGreenLight'
              onClick={() => setShouldShowDropOffScreen(false)}
            >
              View Plans
            </Button>
            <Button 
              sx={{ textDecoration: 'underline', ':hover': { textDecoration: 'underline' } }}
              size='small'
              color='braceGreenDark'
              onClick={() => setIsDeleteAccountOpen(true)}
            >
                Delete Account
            </Button>
          </Box>
        </Box>
      }

      <DeleteAccountDialog
        deleteAccount={handleDelete}
        isOpen={isDeleteAccountOpen} 
        close={() => setIsDeleteAccountOpen(false)}
      />
    </Box>
  );
}