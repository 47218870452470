import React, { createContext, useContext, useEffect, useState  } from 'react';

import { UserContext } from './user';
import { ServicesContext } from './services';
import { FeaturesContext } from './features';
import { areDatesTheSameIgnoringMinutes } from '@helpers/dates';

export const BillingContext = createContext();

export function BillingProvider(props){
  const { user, getUserToken } = useContext(UserContext);
  const { api, logger, payments } = useContext(ServicesContext);
  const { isFeatureEnabled } = useContext(FeaturesContext);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ subscription, setSubscription ] = useState(null);
  const [ isLoadingPaymentsSubscription, setIsLoadingPaymentsSubscription ] = useState(true);
  const [ paymentsSubscription, setPaymentsSubscription ] = useState(null);
  const [ offerings, setOfferings ] = useState([]);
  
  const [ isPurchasingPackage, setIsPurchasingPackage ] = useState(false);
  const [ haveReSyncedSubscriptions, setHaveReSyncedSubscriptions ] = useState(false);

  const syncSubscriptions = (callback) => {
    logger.info('Syncing Subscriptions', { userId: user.id });
    setIsLoading(true);
    api.put({ url: 'billing/subscriptions/sync', getUserToken })
      .then((result) => {
        setSubscription(result);
        setIsLoading(false);
        if(callback){
          callback(subscription);
        }
        
      });
  };

  useEffect(() => {
    if(user?.id && payments.isAvailable()){
      logger.info('Loading Billing Context', { userId: user.id });
      loadSubscription();
      payments.init(user, () => {
        payments.getCustomerInfo((subscription) => {
          setPaymentsSubscription(subscription);
          setIsLoadingPaymentsSubscription(false);
        });
        payments.getOfferings((o) => {
          if(o){
            setOfferings(o);
          }
        });
      }); 
    }
  }, [user]);

  useEffect(() => {
    if(user && 
      (subscription || !isLoading) &&
      (paymentsSubscription || !isLoadingPaymentsSubscription) &&
      !haveReSyncedSubscriptions
    ){
      var shouldSyncSubscriptions = false;

      if(subscription && !subscription.isTrial && !paymentsSubscription){
        shouldSyncSubscriptions = true;
      }
      else if(paymentsSubscription){
        shouldSyncSubscriptions = !subscription ||
          subscription.store.toLowerCase() !== paymentsSubscription.store.toLowerCase() ||
          !areDatesTheSameIgnoringMinutes(subscription.expiresAt, paymentsSubscription.expiresAt) ||
          !areDatesTheSameIgnoringMinutes(subscription.billingIssueDetectedAt, paymentsSubscription.billingIssueDetectedAt ||
          !areDatesTheSameIgnoringMinutes(subscription.unsubscribedAt, paymentsSubscription.unsubscribedAt));
      }

      if(shouldSyncSubscriptions){
        logger.info('Subscriptions are out of sync so going to force a re-sync', { userId: user.id });
        syncSubscriptions();
        setHaveReSyncedSubscriptions(true);
      }else{
        logger.info('Subscriptions are in sync', { userId: user.id });
      }
    }
  }, [subscription, paymentsSubscription]);

  const checkBillingStatus = async (history) => {
    if(isFeatureEnabled('billing') && payments.isAvailable()){
      payments.getOfferings((o) => {
        if(o?.length > 0){
          if(user?.id && user.status != 'PENDING' && !subscription && !isLoading && isFeatureEnabled('billing')){
            history.push('/onboarding/info');
          }else if(subscription && subscription?.status == 'EXPIRED'){
            history.push('/paywall');
          }
          else if(subscription && subscription?.status == 'REFUNDED'){
            history.push('/paywall');
          }
          else if(subscription && subscription?.status == 'OVERDUE'){
            history.push('/paywall');
          }
          else if(subscription && subscription?.status == 'PAUSED'){
            history.push('/paywall');
          }
        }
      });
      
    }  
  };
  
  const loadSubscription = async () => {
    setIsLoading(true);
    var subscription = await api.get({ url: 'billing/subscriptions', params:{ forceSync: true }, getUserToken });
    setSubscription(subscription);
    setIsLoading(false);
  };

  const startFreeTrial = async (callback) => {
    var subscription = await api.put({ url: 'billing/subscriptions/startTrial', getUserToken });
    setSubscription(subscription);
    if(callback){
      callback();
    }
  };

  const purchasePackage = async (p, callback) => {
    setIsPurchasingPackage(true);
    payments.purchasePackage(p, (subscription) => {
      setIsPurchasingPackage(false);
      setPaymentsSubscription(subscription);
      if(subscription){
        syncSubscriptions(callback);
      }
      
    });
  };

  return (
    <BillingContext.Provider value={{ 
      isAvailable: payments.isAvailable() && offerings?.length > 0,
      subscription,
      rcSubscription: paymentsSubscription,
      isLoading,
      checkBillingStatus,
      startFreeTrial,
      offerings,
      purchasePackage,
      isPurchasingPackage
    }}>
      {props.children}
    </BillingContext.Provider>
  );
}