import React, { useContext } from 'react';

import { 
  Box,
  Divider,
  Skeleton,
  Avatar
} from '@mui/material';

import { LikeAndCommentButtons } from '@components/molecules';

import { getFromNow } from '@helpers/dates';

import { LoadableText } from '@components/atoms';

import config from '@config';
import { useTheme } from '@emotion/react';
import { NewPostContext } from '@components/organisms';
import { CategoryChip } from '@components/v2/atoms';
import { FeaturesContext, EventsContext, ServicesContext, UserContext } from '@context';

export default function Topic(props) {
  const IMAGE_SIZE = 65;
  const { topic, isLoading, handleComment, setTopicBeingViewed } = props;
  const { user } = useContext(UserContext);
  const { isFeatureEnabled } = useContext(FeaturesContext);
  const { api } = useContext(ServicesContext);
  const { openNewPostDialog } = useContext(NewPostContext);
  const { trackEvent } = useContext(EventsContext);
  const theme = useTheme();

  const likeTopic = async () => {
    if(!topic.likedByCurrentUser){
      trackEvent('Community:Topic:Like', { topicId: topic.id });
      await api.put({ url: `community/topics/${topic.id}/like`, user });
    }
  };

  const openCommentDialog = async () => {
    trackEvent('Community:Topic:Comment:Create:Open');
    openNewPostDialog({ 
      title: 'New Topic', 
      isImageEnabled: false,
      placeholder: 'Your comment...', 
      buttonAction: async ({ message }) => {
        await api.post({ url: `community/topics/${topic.id}/comments`, user, data: { message } });
        trackEvent('Community:Topic:Comment:Create:Save');
        handleComment(topic.id);
      }, 
      buttonText: 'COMMENT',
      successToastMessage : 'Comment Created!'
    });
  };

  return (
    <>
      <Box sx={{ backgroundColor: theme.palette.background.paper, marginBottom: '1px' }}>
        <Divider light/>
        <Box sx={{ p: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={() => setTopicBeingViewed(topic)} >
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ typography: 'h6', color: 'text.primary', fontWeight: 'medium' }}>
                <LoadableText text={topic.name} isLoading={isLoading} />
              </Box>
              <Box sx={{ display: 'flex' }}>
                {isLoading ? (
                  <Box sx={{ paddingRight: 1 }}>
                    <Skeleton variant="rectangular">
                      <Avatar sx={{ width: IMAGE_SIZE, height: IMAGE_SIZE, borderRadius: 1 }}/>
                    </Skeleton>
                  </Box>
                ) : (
                  <Box>
                    {topic.imageUrl &&
                    <Box sx={{ paddingRight: 1, paddingTop: 0.5 }}>
                      <Avatar 
                        src={`${config.contentBaseUrl}images/${topic.imageUrl}`}
                        sx={{ width: IMAGE_SIZE, height: IMAGE_SIZE, borderRadius: 1 }}
                        imgProps={{ loading: 'lazy', decoding:'async' }} >
                      </Avatar>
                    </Box>
                    }
                  </Box>
                )}
                <Box>
                  <Box sx={{ width: '100%', typography: 'body1', color: 'text.primary', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '3', WebkitBoxOrient: 'vertical' }}>
                    <LoadableText text={topic.description} isLoading={isLoading} minWidth={100}/>
                  </Box>
                  {isFeatureEnabled('new_create_discussion') &&
                    <Box>
                      {topic?.categories?.map(c => (
                        <CategoryChip 
                          sx={{ m: 0.25 }}
                          size='small'
                          key={c.id}
                          category={c}
                        />
                      ))}
                    </Box>
                  }
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 0.25 }}>
            <Box sx={{ typography: 'body2', color: 'text.secondary', fontWeight: 'light' }}>  
              {topic.lastCommentAt &&
              <LoadableText text={`Last Reply ${getFromNow(topic.lastCommentAt)}`} isLoading={isLoading} minWidth={20} />
              }
            </Box>
            <Box sx={{}}>
              <LikeAndCommentButtons 
                isLoading={isLoading}
                data={{ likeCount:topic.likeCount, commentCount:topic.commentCount, likedByCurrentUser: topic.likedByCurrentUser }}
                likeAction = {likeTopic}
                commentAction = {() => openCommentDialog()}
              />
            </Box>
          </Box>
        </Box>
        <Divider light/>
      </Box>
    </>
  );
}