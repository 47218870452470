import React, { createContext, useContext, useState  } from 'react';
import mixpanel from 'mixpanel-browser';

export const DialogContext = createContext({});

import FeedbackDialog from './../components/templates/feedback/feedback-dialog';

export function DialogProvider(props){
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);

  return (
    <DialogContext.Provider value={{
      openFeedbackDialog: () => setIsFeedbackDialogOpen(true)
    }}>
      {props.children}
      <FeedbackDialog isOpen={isFeedbackDialogOpen} close={() => setIsFeedbackDialogOpen(false)}/>
    </DialogContext.Provider>
  );
}