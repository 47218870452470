import React, { useState } from 'react';

import { 
  DialogActions,
  Box,
  Typography,
  TextField
} from '@mui/material';

import { Dialog } from './../atoms';
import { LoadingButton } from '@mui/lab';

export default function DeleteAccountDialog(props){
  var { isOpen, close, deleteAccount } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [feedback, setFeedback] = useState('');

  const handleConfirmation = () => {
    if(activeStep == 0){
      setActiveStep(1);
      return;
    }
    setIsLoading(true);
    deleteAccount(feedback)
      .then(() => {
        setIsLoading(false);
        close();
      });
  };

  const handleClose = () => {
    setActiveStep(0);
    setFeedback('');
    close();
  };

  return (
    <Dialog isOpen={isOpen} close={handleClose} title='Delete Account'>
      <Box sx={{ paddingTop: 1, paddingBottom: 2, px: 2 }}>
        {activeStep == 0 &&
          <>
            <Typography py={1} color='text.secondary'>
              Deleting your account will remove all data linked to your account including plans, sessions and check-ins. It is an irreversible process so please be sure before you click confirm.
            </Typography>
            <Typography py={1} color='text.secondary'>
              NOTE: Please ensure to cancel your subscription to cease payments.
            </Typography>
          </>
        }
        {activeStep == 1 &&
          <>
            <Typography paddingBottom={1} color='text.secondary'>
              We are sorry to see you go! Please provide any feedback on your experience with Brace so that we can improve the app for future users. Thank you and good luck in your recovery!
            </Typography>
            <TextField 
              placeholder='Why are you deleting your account?'
              fullWidth
              multiline
              rows={6}
              onChange={(e) => {setFeedback(e.target.value);}}
              value={feedback}
            />
          </>
        }
      </Box>
      <DialogActions>
        <LoadingButton 
          fullWidth
          disabled={activeStep == 1 && feedback.length < 6}
          variant='contained'
          onClick={handleConfirmation} 
          loading={isLoading}
          color={activeStep == 0 ? 'primary' : 'error'}
          size='large'>
          {activeStep == 0 ? 'Continue' : 'Confirm Deletion'}
            
        </LoadingButton>
      </DialogActions>
    </Dialog>
    
  );
}