import React from 'react';

import { 
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import { DialogTransition, Icon } from '@components/atoms';

export default function SuccessDialog(props) {
  const { title, isOpen, close, iconType, message, action, actionText } = props;
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={DialogTransition}
      onClose={close}
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <Box width="100%"
          display='flex'
          justifyContent='center'
          direction='column'>
          <Box>
            <Icon type={iconType ?? 'strength'} delay="0"/>
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          {message}
        </Box>
      </DialogContent>
      <DialogActions>
        {action &&
          <Button color='primary' variant='contained' onClick={ action }>{actionText}</Button>
        }
        <Button onClick={close}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}