import React from 'react';

export default function Crown(){
  return (
    <svg 
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_13050_165)">
        <path d="M11.8912 15.1363H6.0821C5.53664 15.1226 5.0253 14.9181 4.61621 14.5635C4.20712 14.209 3.94121 13.7181 3.84576 13.1794L2.87759 5.47491C2.8094 5.02491 3.05485 4.87492 3.42303 5.14083L6.22529 7.17946L8.70712 3.06807C8.7344 3.00671 8.76847 2.95901 8.82302 2.92492C8.88438 2.89083 8.93894 2.87036 9.00712 2.87036C9.07531 2.87036 9.12986 2.89083 9.1844 2.92492C9.24577 2.95901 9.27985 3.01353 9.30713 3.06807L11.789 7.17946L14.5844 5.14083C14.9594 4.87492 15.2321 5.02491 15.1298 5.47491L14.1617 13.1794C14.073 13.7181 13.7935 14.209 13.3776 14.5703C12.9617 14.9249 12.4435 15.1294 11.898 15.1363H11.8912Z"
          strokeWidth="1.02273"
          strokeLinecap="round"
          strokeLinejoin="round"/>
        <path d="M7.60938 11.5703H10.3366"
          fill='#fff'
          stroke='#fff'
          strokeWidth="1.02273"
          strokeLinecap="round"
          strokeLinejoin="round"/>
      </g>
    </svg>
    
  );
}