import React from 'react';

import { 
  IconButton as MuiIconButton
} from '@mui/material';

export default function IconButton(props){
  return (
    <MuiIconButton 
      {...props}
    /> 
  );
}