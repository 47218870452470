import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import queryString from 'query-string';

import { 
  Box,
  CircularProgress,
  Stack,
  Divider,
  Fab
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { EventsContext } from '@context/events';
import { NewPostContext } from '@components/organisms';
import Topic from './topic';
import { ServicesContext } from '@context/services';
import { UserContext } from '@context/user';
import moment from 'moment';
import { CommunityFilterContext, CommunityFilters } from '@components/v2/organisms';
import { FeaturesContext } from '@context/features';
import CreateTopicDialog from './create/create-topic-dialog';
import { EmptyState } from '@components/molecules';
import ViewTopic from './topic/topic';
import { useLocation } from 'react-router-dom';

const EmptyLoadingState = () => {
  var rows = [];
  for (var i = 0; i < 10; i++) {
    rows.push(<Topic key={i} topic={{}} isLoading={true}/>);
  }
  return rows;
};

export default function TopicFeed() {
  const { trackEvent } = useContext(EventsContext);
  const { search } = useLocation();
  const { openNewPostDialog } = useContext(NewPostContext);
  const { initialize, sortBy, searchTerm } = useContext(CommunityFilterContext);
  const { isFeatureEnabled } = useContext(FeaturesContext);
  const [topics, setTopics] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [anchor, setAnchor] = useState(moment());
  const [moreResultsLeft, setMoreResultsLeft] = useState(true);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [topicBeingViewed, setTopicBeingViewed] = useState(null);
  const { api } = useContext(ServicesContext);
  const { user, getUserToken } = useContext(UserContext);
  const values = queryString.parse(search);
  
  useEffect(() => {
    initialize({ isSearchEnabled: true, defaultSortBy: 'LATEST' });
    trackEvent('Community:Topics:Open');
  }, []);

  useEffect(() => {
    if(sortBy){
      var newAnchor = moment();
      setAnchor(newAnchor);
      searchTopics([], 0, newAnchor);
    }
  }, [sortBy, searchTerm ]);

  useEffect(() => {
    if(values?.topicId && user?.id){
      var post = topics.filter(p => p.id === values?.topicId)[0];

      if(post?.id){
        setTopicBeingViewed(post);
      }else{
        api.get({ url: `community/topics/${values.topicId}`, user })
          .then(p => setTopicBeingViewed(p));
      }
    }else if(topicBeingViewed?.id){
      setTopicBeingViewed(null);
    }
  }, [values?.topicId]);

  const openCreateDiscussion = async () => {
    trackEvent('Community:Topic:Create:Open');
    if(isFeatureEnabled('new_create_discussion')){
      setIsCreateDialogOpen(true);
    }else{
      openNewPostDialog({ 
        title: 'New Discussion', 
        requiresTitle: true,
        placeholder: 'Your Discussion..', 
        buttonAction: async ({ message, title, image }) => {
          const formData = new FormData();
          formData.append('name', title);
          formData.append('description', message);
    
          if(image){
            formData.append('image', image);
          }
    
          var topic = await api.post({ url: 'community/topics', data: formData, getUserToken });
          trackEvent('Community:Topic:Create:Save');
          await handleNewTopic(topic);
        
        }, 
        successToastMessage : 'Topic Created!'
      });
    }
  };

  const searchTopics = async (existingTopics, page, anchorParam) => {
    var params = {
      page,
      searchTerm,
      createdAtLimit: (anchorParam ?? anchor).toISOString(),
      sortBy
    };

    var result = await api.get({ url: 'community/topics/search', params, getUserToken });
    setIsLoading(false);
    setMoreResultsLeft(result.results.length != 0);
    setTopics(existingTopics.concat(result.results));
  };

  const refresh = async () => {
    setAnchor(moment());
    setCurrentPage(0);
    setIsRefreshing(true);
    await searchTopics([], 0, moment());
    setIsRefreshing(false);
  };

  const loadNextPage = async () => {
    setCurrentPage(currentPage + 1);
    await searchTopics(topics, currentPage + 1, anchor);
  };

  const handleComment = async (topicId) => {
    setTopics(
      topics.map(topic  =>{
        if(topic.id === topicId){
          topic.commentCount += 1;
          return topic;
        }else{
          return topic;
        }
      })
    );
  };

  const handleNewTopic = async (topic) => {
    setTopics([topic].concat(topics));
  };

  return (
    <Stack 
      direction="column" 
      sx={{ width: '100%' }}>
      <Divider/>
      <CommunityFilters />
      <Box>
        {(isLoading && topics.length === 0)  &&
          <EmptyLoadingState />
        }
        {(!isLoading && !isRefreshing && topics.length === 0 ) &&
      (
        <Box p={2}>
          <EmptyState iconType={'search' }
            title='No Matches Found' 
            message='Please try another search' />
        </Box> 
      )}
        {isRefreshing && 
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
          <CircularProgress size={'1.5rem'}/>
        </Box>
        }
        {!isLoading && topics.length > 0 &&
        <InfiniteScroll
          dataLength={topics.length}
          next={loadNextPage}
          hasMore={moreResultsLeft}
          loader={
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
              <CircularProgress size={'1.5rem'}/>
            </Box>
          }
          refreshFunction={refresh}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
        >
          {topics.map((topic) =>
            <Topic 
              setTopicBeingViewed={setTopicBeingViewed}
              topic={topic}
              key={topic.id}
              isLoading={isLoading}
              handleComment={handleComment}/>     
          )}
        </InfiniteScroll>
        }

      </Box>
      <CreateTopicDialog 
        isOpen={isCreateDialogOpen}
        close={(topic) => {
          if(topic?.id){
            handleNewTopic(topic);
          }
          setIsCreateDialogOpen(false);
        }}/>
      <Box sx={{ 
        position: 'fixed',
        right: 4,
        bottom: 'calc(60px + env(safe-area-inset-bottom))'
      }}>
        <Fab 
          data-testid='community-topics-fab-new-topics'
          size="large" 
          color="secondary" 
          id="basic-button"
          onClick={() => openCreateDiscussion()}>
          <AddIcon />
        </Fab>
      </Box>
      <ViewTopic 
        backLocation={values?.topicId ? '/#/community' : null}
        topicBeingViewed={topicBeingViewed}
        setTopicBeingViewed={setTopicBeingViewed}
      />
    </Stack>
  );
}