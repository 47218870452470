import React from 'react';

import { 
  SvgIcon
} from '@mui/material';

import Crown from './crown';
import Chat from './chat';
import { useTheme } from '@emotion/react';

const getIcon = (name) => {
  if(name == 'svg/chat'){
    return <Chat />;
  }
  return <Crown />;
};

export default function Icon(props){
  var theme = useTheme();
  var { type, color=theme.palette.braceGreenDark, size=32, sx={} } = props;

  return (
    <SvgIcon sx={{
      ...sx,
      stroke: color.main,
      fill: color.main,
      height: size,
      width: size
    }}>
      {getIcon(type)}
    </SvgIcon>
    
  );
}